<template>
  <div
    :class="'h-105'"
    class="main-account"
    :style="{ 'padding-top': getBreakPoint ? '19px' : '' }"
  >
    <v-container style="max-width: 1350px" class="pa-0">
      <Header v-if="!getBreakPoint" />
      <header-mobile v-else />
      <router-view />
    </v-container>
  </div>
</template>
<script>

export default {
  name: "MainAccount",
  components: {
    Header: () => import("../components/Account/Header"),
    HeaderMobile: () => import("../components/Account/HeaderMobile.vue")
  },

  computed: {
    getRouteName() {
      return this.$route.name;
    },
    getBreakPoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (!token) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.main-account {
  background: #f2f2f7 !important;
}

.h-100 {
  height: 100% !important;
}
.h-105 {
  height: 107% !important;
}

.h-120 {
  height: 120% !important;
}
</style>
